<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow width="600" height="180" :steps="workflowSteps" :currentStep="workflowIndex" :labelWidth="200"
        :lineWidth="140"></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="ACS > rôles des services" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading || running"></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="font-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div>Consulter les rôles associés aux services</div>

              <v-spacer></v-spacer>

              <!-- Bouton de mode modification   -->
              <v-btn v-if="canEditRole && !modeEdition" icon color="primary" @click="clickOnModeEdition">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-row justify="end" no-gutters v-if="modeEdition">
                <v-btn class="btn" color="primary" text @click="clickOnCancelEdit" :disabled="loading">
                  Quitter l'édition
                </v-btn>

                <v-btn icon color="primary" :disabled="!hasChanged || loading" @click="save">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="availableItems" item-key="name" :search="search"
              :custom-filter="filterOnlyCapsText" disable-pagination hide-default-footer sort-by="role">
              <!-- Template d'analyse des items en fonction des colonnes pour modifier l'affichage du contenu des cellules -->
              <template v-for="head in headers" v-slot:[`item.${head.value}`]="{ item }">
                <div :key="head.value">
                  <!-- Colonne "rôle" on affiche le role -->
                  <div v-if="head.value == 'role'">
                    {{ item[head.value] }}
                  </div>

                  <!-- Pour les autres colonnes, on traite l'affichage -->
                  <div v-else>
                    <v-row no-gutters justify="center">
                      <v-icon v-if="item[head.value].hasRole && !modeEdition" small class="mr-2" color="primary">
                        mdi-check
                      </v-icon>

                      <v-checkbox v-if="modeEdition && !item[head.value].isHerited"
                        off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-outline"
                        v-model="item[head.value].hasRole" color="primary" hide-details class="my-0"
                        @click="onClickCheckBox(item['role'], head.value)"></v-checkbox>

                      <!-- Tooltip conteant l'icône d'héritage -->
                      <v-tooltip bottom v-if="item[head.value].isHerited">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" color="secondary" v-bind="attrs" v-on="on">
                            mdi-account-switch-outline
                          </v-icon>
                        </template>
                        <span>Héritage par rôle supérieur</span>
                      </v-tooltip>
                    </v-row>
                  </div>
                </div>
              </template>

              <template v-slot:top>
                <v-row class="mb-2">
                  <v-checkbox class="mx-2" on-icon="mdi-checkbox-outline" off-icon="mdi-checkbox-blank-outline"
                    v-model="viewAllRole" hide-details label="Afficher les rôles non associés" />

                  <v-text-field v-model="search" label="Rechercher un rôle" class="mx-4 ml-16"></v-text-field>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions v-if="modeEdition">
            <v-spacer></v-spacer>
            <v-btn outlined class="ma-2 px-4 btn" color="primary" :disabled="!hasChanged || loading" @click="save">
              <div class="capitalize">enregistrer</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AlertNotSavedModifsComponent :show="showAlertQuit" @quit="onQuitAlert" @notquit="onNotQuitAlert" />

    <!-- afficher des messages -->
    <v-snackbar v-model="snackbarVisible" :color="snackbarColor" :timeout="snackbarTimeout" :left="snackbarLeft"
      :right="snackbarRight" :top="snackbarTop" :bottom="snackbarBottom">{{ snackbarMessage }}</v-snackbar>
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import * as exceptions from "@/service/exception_to_message.js";

import AuthAdminService from "@/service/backend/auth_admin_service.js";

import * as logger from "@/tools/logger.js";

import { hasRoles } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

import { RolesApmEnum } from "@/service/roles/roles_apm";

export default {
  name: "ServiceRolesAcs",

  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /** L'object service métier */
      serviceAuthAdminBackend: null,

      // variable pour le champ de recherche
      search: "",

      /** variable pour l'affichage de chargement de données */
      loading: false,
      running: false,

      /** Fait aparaître ou non les rôles qui ne sont pas affectés */
      viewAllRole: false,

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: false,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      /** Tableau des headers de la vue table */
      headers: [],

      /** Remplissage de la matrice */
      items: [],
      /** les éléments source de la matrice */
      itemsSource: [],

      /** Tableau des rôles héritage compris */
      rolesApmSource: [],

      /** Tableau des services traités par acs */
      services: [],

      /** Définition du rôle nécessaire pour l'édition de la vue */
      roleForEdition: [RolesApplicationEnum.EditAcs],

      /** Liste des lignes sur lesquelles il y a  des changements */
      changedRoles:[]
    };
  },
  methods: {
    /** Méthode de chargement de la vue */
    async load() {
      try {
        logger.debug("Chargement des données de la vue.");
        this.loading = true;

        // Récupération des rôles acs avec héritage
        this.rolesApmSource = await this.serviceAuthAdminBackend.getRoles();
        //logger.debug("Roles apm", this.rolesApmSource[0]);

        // Récupération desrôles pour l'ensemble des services
        this.services =
          await this.serviceAuthAdminBackend.getRolesForServices();
        //logger.debug("Roles services", this.services[0]);

        // Construction des headers de la matrice
        this.buildHeaders();
        // Construction des rows (héritage compris)
        this.buildItems();

        this.itemsSource = [];
        this.itemsSource = JSON.parse(JSON.stringify(this.items));

        //TODO to delete
        let newRoles = [];
        for (let role of this.rolesApmSource) {
          if (!RolesApmEnum[role.name]) {
            newRoles.push(role);
          }
        }

        logger.debug("NOUVEAU ROLES", newRoles);
      } catch (error) {
        logger.error("Erreur de chargement des datas.  " + error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Diff et sauvegarde des modifications */
    async save() {
      try {
        this.loading = true;

        logger.debug("SAVE");
        let serviceChanged = this.findRolesHasChanged();

        // Changement ?
        if (serviceChanged.length > 0) {
          // Parcours la liste des services qui ont changés
          for (let serviceName of serviceChanged) {
            let roles = [];

            // Parcours de chaque items (row)
            for (let item of this.items) {
              // Le service en cours à t-il le rôle en cours
              if (item[serviceName.toLowerCase()].hasRole) {
                // Construit la liste des rôles du service
                roles.push(item.role);
              }
            }
            // Récupère le service dans la source
            let serviceSource = this.services.find(
              (s) => s.name == serviceName
            );
            // Remplace le tableau de rôles par le nouveau
            serviceSource.authorized = roles;
            // Sauvegarde des rôles du service
            await this.serviceAuthAdminBackend.updateRolesForServices(
              serviceSource
            );

            // Parcours de la liste des items source pour mise à jour
            for (let itemSrc of this.itemsSource) {
              // Récupération de l'item identique à la source en cours
              let item = this.items.find((i) => i.role == itemSrc.role);

              // Recopie pour le service en cours la valeur affichée dans la source
              itemSrc[serviceName.toLowerCase()].hasRole =
                item[serviceName.toLowerCase()].hasRole;
            }
          }
          this.modeEdition = false;
          this.changedRoles = [];
        }
      } catch (error) {
        logger.error("ERROR service to save", error);
        this.addErrorToSnackbar(
          "sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trouve les services qui ont changés et les retourne en un tableau
     */
    findRolesHasChanged() {
      let serviceChanged = [];

      // Parcours tous les services
      for (let service of this.services) {
        // Met le nom du service en minuscule
        let serviceName = service.name.toLowerCase();

        // Parcours les roles sources
        for (let itemSrc of this.itemsSource) {
          // Récupère le clone du rôle enc ours dans la liste affichée
          let item = this.items.find((i) => i.role == itemSrc.role);

          // Vérification de changement de check dus ervice pour le rôle en cours
          if (item[serviceName].hasRole != itemSrc[serviceName].hasRole) {
            serviceChanged.push(service.name);
            break;
          }
        }
      }

      return serviceChanged;
    },

    /** Construction des headers de la table */
    buildHeaders() {
      // Ajout de la colone des rôles
      this.headers.push({
        text: "Rôle",
        value: "role",
        width: "150px",
      });

      let headers = [];

      // Parcours du tableau de service pour que chaque service soit une colonne
      for (let service of this.services) {
        headers.push({
          text: service.name,
          value: service.name.toLowerCase(),
          sortable: false,
          align: "center",
        });
      }
      // Tri dans l'ordre alphabétique
      headers = headers.sort(function (a, b) {
        return a.text.localeCompare(b.text);
      });

      // Ajout des colonnes triées
      this.headers.push(...headers);
    },

    /** Construction des lignes de  la matrice */
    buildItems() {
      let items = [];

      // Parcours de l'ensemble des rôles pour la construction des lignes
      for (let role of this.rolesApmSource) {
        let r = {};
        r.role = role.name;

        // Parcours l'ensemble des services pour le matching colonne
        for (let service of this.services) {
          // Recherche si le rôle en cours est présent dans le service
          let foundRole = service.authorized.find((x) => x == role.name);

          r[service.name.toLowerCase()] = {};
          r[service.name.toLowerCase()].hasRole = false;
          r[service.name.toLowerCase()].isHerited = false;

          // Le rôle est trouvé on met un marqueur dans la cellule ( rôle -- service )
          if (foundRole) {
            r[service.name.toLowerCase()].hasRole = true;
          }
        }

        // Ajout de la ligne dans la table
        items.push(r);
      }

      // Parcours des colonnes
      for (let service of this.services) {
        let serviceName = service.name.toLowerCase();

        // Parcours des rows
        for (let row of items) {
          // Si la cellule est settée
          if (row[serviceName].hasRole) {
            // Récupération du rôle apm de la ligne en cours
            let role = this.rolesApmSource.find((r) => r.name == row.role);

            // Parcours des rôles hérités
            for (let herited of role.grant) {
              // Récupération de la row avec nom identique à héritage
              let it = items.find((i) => i.role == herited);

              // Sette la cellule cible en héritage
              it[serviceName].isHerited = true;
            }
          }
        }
      } // fin héritage

      // tri ordre alphébétique des rows
      items = items.sort(function (a, b) {
        return a.role.localeCompare(b.role);
      });

      this.items = [...items];
    },

    /** Méthode de recherche d'un rôle */
    filterOnlyCapsText(value, search) {
      search = search.toUpperCase();

      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },

    /** Traite le click sur une checkbox
     * Reçois la ligne et la colonne
     * en fonction, attribu ou non l'héritage
     */
    onClickCheckBox(row, col) {
      this.changedRoles.push(row);

      // Récupère la ligne dans la liste d'items
      let item = this.items.find((i) => i.role == row);

      // Récupère l'état du check de la cellule
      let check = item[col].hasRole;

      // récupère le rôle dans la liste originale
      let roleApm = this.rolesApmSource.find((r) => r.name == row);

      // Parcours la liste des rôles hérités
      for (let role of roleApm.grant) {
        // Récupère la row héritée
        let it = this.items.find((i) => i.role == role);
        // La valeur d'héritage est mise à la même valeur que celle du parent
        it[col].isHerited = check;

        // Si le rôle hérité était déjà check, on met la valeur à faux.
        if (check) {
          it[col].hasRole = false;
        }
      }
    },

    /** Annule le mode édition et remet les modifications dans l'état initial */
    clickOnCancelEdit() {
      if (this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function (value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.changedRoles = [];
            this.items = JSON.parse(JSON.stringify(this.itemsSource));
          }
        };
      } else {
        this.modeEdition = false;
        this.changedRoles = [];
      }
    },

    /** Evènement d'activation du mode édition */
    clickOnModeEdition() {
      this.modeEdition = true;
    },
  },
  computed: {
    /** Retourne soit les rôles qui ont au moins une association, soit tous les rôles */
    availableItems() {
      let items = [];

      // Si la coche "tous les rôles" est active, on retourne TOUS les rôles
      if (this.viewAllRole) {
        return this.items;
      }

      // Parcours l'ensemble des rows
      for (let item of this.items) {
        let addItem = false;
        let roleChanged = false;

        // Parcours les colonnes de la row
        for (let i in item) {
          if (i == "role") {
            continue;
          } else {
            // On trouve une cellule settée, pas besoin de continuer à analyser les autres colonnes
            if (item[i].hasRole || item[i].isHerited) {
              addItem = true;
              break;
            }
          }
          if (this.changedRoles.find((i) => i == item.role)) {
            roleChanged = true;
          }
        }
        // Au moins une cellule settée trouvée, on ajoute l'item
        if (addItem || roleChanged) {
          items.push(item);
        }
      }
      return items;
    },

    /** retourne vrai si un des items à changé par rapport à sa source */
    hasChanged() {
      let changed = false;

      /** Le tableau source est à null --> le modèle ne peut avoir changé */
      if (!this.rolesApmSource) return false;

      let find = this.findRolesHasChanged();
      if (find.length > 0) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    // Configure les éléments si on peut éditer les rôles
    this.canEditRole = hasRoles(this.roleForEdition);

    //on affiche le bouton retour
    this.showBackButton = true;

    this.addStepForWorkflow("Choisir un périmètre");
    this.addStepForWorkflow("Consulter la configuration");
    this.nextStepForWorkflow();

    this.serviceAuthAdminBackend = new AuthAdminService(
      this.$api.getAuthAdminApi()
    );

    this.load();
  },
};
</script>
